import React from "react"
import Maps from "../components/maps"
import Address from "../components/address"

const OurLocation = () => (
  <>
    <section id="our-location" className="our-location">
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-8">
            <Maps />
          </div>
          <div className="col-lg-4">
            <div className="row">
              <div className="col">
                <Address />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
)

export default OurLocation
