import React from "react"
import TeamMember from "./teamMember"
import { graphql, useStaticQuery } from "gatsby"

const TeamMembers = () => {
  const teamMembers = useStaticQuery(graphql`
    query TeamMembers {
      allMarkdownRemark(
        sort: { fields: frontmatter___order }
        filter: { frontmatter: { type: { eq: "team" } } }
      ) {
        totalCount
        edges {
          node {
            id
            frontmatter {
              name
              title
              subtitle
              profileImage {
                childImageSharp {
                  fluid(maxWidth: 200, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            html
          }
        }
      }
    }
  `)

  return (
    <>
      {teamMembers.allMarkdownRemark.edges.map(({ node }) => (
        <TeamMember
          key={node.id}
          name={node.frontmatter.name}
          title={node.frontmatter.title}
          subtitle={node.frontmatter.subtitle}
          image={node.frontmatter.profileImage}
          html={node.html}
        />
      ))}
    </>
  )
}

export default TeamMembers
