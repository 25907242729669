import React from "react"

const AboutUs = () => (
  <>
    <p>
      Information Technology & Services company providing personal consultancy
      and services. Located in Rotterdam, Netherlands and facilitating clients
      since 2013 across Europe with onsite installations, software development
      projects (DevOps) and sharing technological expertise.
    </p>
  </>
)

export default AboutUs
