import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Link } from "gatsby"
import Services from "../components/services"
import TeamMembers from "../sections/teamMembers"
import OurLocation from "../sections/ourLocation"
import ContactForm from "../sections/contactForm"
import AboutUs from "../components/aboutUs"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <article>
      <section id="about-us" className="aboutUs">
        <div className="container py-5">
          <div className="row justify-content-center lead pt-5">
            <AboutUs />
          </div>
        </div>
      </section>

      <section id="services" className="services">
        <div className="container py-5">
          <h2 className="row justify-content-center dubbelbit">Services</h2>
          <Services />

          <div className="row justify-content-center pt-5">
            <Link to="/services" className="btn btn-lg btn-primary">
              More details
            </Link>
          </div>
        </div>
      </section>

      <TeamMembers />

      <ContactForm />

      <OurLocation />
    </article>
  </Layout>
)

export default IndexPage
