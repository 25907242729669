import React from "react"
import { withPrefix, Link } from "gatsby"
import TeamMembers from "../components/teamMembers"

const TeamMembersSection = () => {
  const isHomePage =
    typeof window !== "undefined" &&
    window.location.pathname === withPrefix("/")
  return (
    <>
      <section id="team" className="team">
        <div className="container py-5">
          <h1 className="row justify-content-center">Team</h1>
          <div className="row py-5">
            <TeamMembers />
          </div>

          <div className="row justify-content-center">
            {isHomePage && (
              <Link to="about-us" className="btn btn-lg btn-primary">
                Tell me more
              </Link>
            )}
            {!isHomePage && (
              <a
                href="mailto:info@dubbelbit.nl?subject=Request for information"
                className="btn btn-lg btn-primary"
              >
                Hire us
              </a>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default TeamMembersSection
