import React from "react"

const Map = () => (
  <>
    <iframe
      title="address"
      className="map"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d64993.62593002484!2d4.405090872739311!3d51.892059977953345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c44e286e4c6ed5%3A0xabf943faccb2e4c6!2sDubbelbit%20B.V.!5e0!3m2!1sen!2snl!4v1589146189415!5m2!1sen!2snl"
      width="600"
      height="450"
      frameBorder="0"
      allowFullScreen=""
      aria-hidden="false"
    ></iframe>
  </>
)

export default Map
