import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { withPrefix } from "gatsby"

const TeamMember = ({ name, title, subtitle, image, html }) => {
  const isHomePage =
    typeof window !== "undefined" &&
    window.location.pathname === withPrefix("/")
  return (
    <>
      <div className="col-lg-4">
        <div className="d-flex justify-content-center">
          <Img
            fluid={image.childImageSharp.fluid}
            className="img-fluid rounded-circle team-member"
            alt={name}
          ></Img>
        </div>
        <h4 className="mt-4 dubbelbit">{name}</h4>
        <p className="lead ">
          {title}
          <br /> {subtitle}
        </p>
        {!isHomePage && (
          <div
            className="bio lead"
            dangerouslySetInnerHTML={{ __html: html }}
          ></div>
        )}
      </div>
    </>
  )
}
TeamMember.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  image: PropTypes.object.isRequired,
  html: PropTypes.string,
}

export default TeamMember
